@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

html {
  direction: rtl;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.05em;
}

[dir="rtl"] .select {
  background-position: calc(100% - (100% - 20px)) calc(1px + 50%),
    calc(100% - (100% - 16px)) calc(1px + 50%) !important;
}

.breadcrumbs > ul > li + *:before,
.breadcrumbs > ol > li + *:before {
  --tw-rotate: 315deg;
}
